<template>
  <v-footer
    id="home-footer"
    color="primary"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          class="text-center"
          cols="12"
        >
          Copyright &copy; {{ new Date().getFullYear() }} NewMotoAsset GmbH, v{{ version || '1.0' }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        'Facebook',
        'Twitter',
        'Instagram',
        'Linkedin',
      ],
    }),
    computed: {
      version () {
        return '' + process.env.VUE_APP_VERSION
      },
    },
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
